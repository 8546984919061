import ApiProductFaq from 'types/api/ProductFAQ';

import Model from 'models/Model';
export default class ProductFAQ implements Model {
    id: string;
    title: string;
    content: string;
    order: number;

    constructor(data: ApiProductFaq) {
        this.id = data.id;
        this.title = data.title || null;
        this.content = data.content || null;
        this.order = data.order;
    }
}
