import ApiBodyPart from 'types/api/BodyPart';

import Model from 'models/Model';

export default class BodyPart implements Model {
    id: string;
    name: string;

    constructor(data: ApiBodyPart) {
        this.id = data.id;
        this.name = data.name;
    }
}
