export default {
    fontPrimary: 'Lato, Arial, sans-serif',

    desktopXL: '1920px',
    desktopL: '1750px',
    desktopM: '1600px',
    desktopS: '1360px',
    desktopXS: '1200px',

    tabletL: '1024px',
    tabletM: '960px',
    tabletS: '768px',

    mobileL: '640px',
    mobileM: '480px',
    mobileS: '360px',
    mobileXS: '281px',

    colorText: '#272834',
    colorTextLight: '#7b7b7b',
    colorTextLighter: '#c1c1c1',

    colorBackground: '#F0F4F5',
    colorBackgroundBorder: '#CCCCCC',
    colorBackgroundBorderLight: '#ececec',

    colorContrast: '#06808E',
    colorContrastLighter: '#0bb6c9',
    colorInfo: '#1cb3b8',
    colorSuccess: '#62b81c',
    colorWarning: '#eb8817',
    colorAlert: '#b8211c',
    colorTextPrimary: '#BA9449',
    colorTextGreen: '#819566',

    colorBackgroundContrast: '#FFFFFF',
    colorBackgroundContrastDark: '#f3f3f3',
    colorBackgroundContrastDarker: '#c3c3c3',
    colorBackgroundContrastDarkest: '#949494',
    colorBackgroundContrastBorder: '#EEEEEE',
    colorBackgroundContrastBorderDarker: '#CCCCCC',
    colorBackgroundGreen: '#829466',

    firstColorSpinner: '#CFB799',
    secondColorSpinner: '#C19A6A',
    thirdColorSpinner: '#BA9449',

    sectionPadding: '4em 0',
    sectionPaddingMobile: '2em 0',
};
