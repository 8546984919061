import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: ${variables.colorBackgroundGreen};
    padding: 80px 100px;

    .header-logo {
        width: 20.625em;
        height: 4.75em;
        margin: 0 auto 65px auto;
        display: block;
    }

    .modal {
        margin: 0 100px;
        padding: 2.688em 3.813em 4.5em;
        border-radius: 20px;
        text-align: center;
        max-width: 1200px;
        background: ${variables.colorBackgroundContrast};
        color: ${variables.colorTextGreen};

        &__header {
            font-size: 3.5em;
            text-transform: uppercase;
            margin-bottom: 20px;
            letter-spacing: 0.56px;
        }

        &__paragraph {
            font-size: 1.875em;
            margin-bottom: 20px;
            line-height: 1.5;

            &__link {
                color: ${variables.colorTextGreen};
            }
        }
    }

    @media all and (max-width: ${variables.tabletM}) {
        padding: 60px 80px;

        .modal {
            margin: 0 60px;
            padding: 2.688em 3.813em 3.5em;
        }
    }

    @media all and (max-width: ${variables.tabletS}) {
        height: 100%;
    }

    
    @media all and (max-width: ${variables.mobileM}) {
        padding: 40px 60px;
        .modal {
            margin: 0 20px;
            padding: 2.375em 2.375em 2.013em;
        }
    }
`;
