import ApiDiscountCode from 'types/api/DiscountCode';

import Model from 'models/Model';

import { changeFromCent, displayPrice } from 'utils/math';

import Cart from './Cart';

export enum Types {
    Percentage = 'percentage',
    Amount = 'amount',
}

export default class DiscountCode implements Model {
    id: string;
    discountType: Types;
    discountValue: number;
    discountValueDisplay: string;
    code: string;
    cart?: Cart;

    constructor(data: ApiDiscountCode) {
        this.id = data.id;
        this.discountType = data.discountType;
        this.discountValue = this.discountType === Types.Amount
            ? changeFromCent(data.discountValue)
            : data.discountValue;
        this.discountValueDisplay = this.getDiscountCodeValueDisplay();
        this.code = data.code;
    }

    getDiscountCodeValueDisplay = (): string => {
        if(this.discountType === Types.Amount) {
            return '£ ' + displayPrice(this.discountValue);
        }

        if(this.discountType === Types.Percentage) {
            return this.discountValue + '%';
        }

        return null;
    };
}
