
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { State } from 'store/state';

import Component from './component';

const mapStateToProps = (state: State): object => {
    return {
        //
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        //
    };
};

Component.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
