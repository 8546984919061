import { ApiResources } from 'types/apiResources';
import { Endpoints } from 'types/endpoints';
import { LocalStorageKeys } from 'types/localStorage';
import { ActionResponse } from 'types/redux/ActionResponse';
import { ReduxRequestPassOptions } from 'types/request';

import Cart from 'models/Cart';
import CartItem, { Types as CartItemTypes } from 'models/CartItem';
import GtmVoucherCartItem from 'models/GtmVoucherCartItem';

import { withVariables } from 'utils/string';
import { reduxRequest as request } from 'services/Api';
import LocalStorage from 'services/LocalStorage';
import Tracking from 'services/Tracking';

import { State } from 'store/state';

import * as types from './types';

export interface CurrentParams { }
export const current = (params?: CurrentParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: () => State): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.GET_CART,
            method: 'GET',
            path: withVariables(Endpoints.PublicCarts, params),
            params: {
                ...params,
                currency: getState()?.user?.market?.currencies[0],
            },
            requestParams: params,
            ...requestParams,
        })
    )
        .then((response) => {
            const responseData = response?.payload?.data;
            const responseResource = responseData && responseData[ApiResources.Cart];
            if (responseResource) {
                LocalStorage.setItem(LocalStorageKeys.Cart, responseResource);
            }

            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};


export interface SetCurrentParams {
    cart: Cart;
}
export const setCurrent = (params?: SetCurrentParams) => (dispatch: Function, getState: () => State): Promise<ActionResponse> => {
    return dispatch({
        type: types.SET_CART,
        payload: params,
    });
};

export interface CreateCartItemParams {
    cartId: string;
    locationId?: string;
    itemType: CartItemTypes;
    itemId: string;
    quantity?: number;
    isAccepted?: boolean;
    data?: {
        voucherType: string;
        voucherValue: number;
        voucherPrice: number;
        voucherFirstName: string;
        voucherLastName: string;
        voucherEmail?: string;
        voucherLocationId: string;
        voucherText?: string;
    };
}

export const createCartItem = (params?: CreateCartItemParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    const cartResource = LocalStorage.getItem(LocalStorageKeys.Cart) || getState()?.user?.cart;
    const dispatchCreateCartItemRequest = (newCartResource?: Cart) => {
        return dispatch(
            request({
                getState,
                reduxType: types.CREATE_CART_ITEM,
                method: 'POST',
                path: withVariables(Endpoints.PublicCartItems, params),
                params: {
                    ...params,
                    currency: getState()?.user?.market?.currencies[0],
                },
                requestParams: {
                    ...params,
                    cartId: params?.cartId ?? newCartResource?.id,
                },
                ...requestParams,
            })
        )
            .then((response) => {
                if (response.payload.data.cartItem.itemType === CartItemTypes.Voucher) {
                    Tracking.eventAddVoucherToCart(new GtmVoucherCartItem(response.payload.data.cartItem));
                } else {
                    Tracking.eventAddToCart(new CartItem(response.payload.data.cartItem));
                }

                LocalStorage.setItem(LocalStorageKeys.LastCartUpdate, { timestamp: Date.now() });
                dispatch(current());
                return response;
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    };

    if (!cartResource) {
        return dispatch(current())
            .then((response) => {
                const responseData = response?.payload?.data;
                const responseResource = responseData && responseData[ApiResources.Cart];

                return dispatchCreateCartItemRequest(responseResource);
            })
            .catch(error => {
                console.error(error);
                throw error;
            });
    } else {
        return dispatchCreateCartItemRequest();
    }
};

export interface UpdateCartItemParams {
    id: string;
    quantity?: number;
    isAccepted?: boolean;
    data?: {
        voucherType: string;
        voucherValue: number;
        voucherPrice?: number;
        voucherFirstName: string;
        voucherLastName: string;
        voucherEmail?: string;
        voucherLocationId: string;
        voucherText?: string;
        voucherCurrency?: string;
    };
}
export const updateCartItem = (params?: UpdateCartItemParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.UPDATE_CART_ITEM,
            method: 'PUT',
            path: withVariables(Endpoints.PublicCartItem, params),
            params: {
                ...params,
                currency: getState()?.user?.market?.currencies[0],
            },
            requestParams: params,
            ...requestParams,
        })
    )
        .then((response) => {
            dispatch(current());
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface SetDiscountCodeParams {
    id: string;
    code: string;
}
export const setDiscountCode = (params?: SetDiscountCodeParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.SET_DISCOUNT_CODE,
            method: 'PUT',
            path: withVariables(Endpoints.PublicCartDiscountCode, params),
            params,
            requestParams: params,
            ...requestParams,
        })
    )
        .then((response) => {
            dispatch(current());
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};

export interface RemoveOfferParams {
    id: string;
    cartItem: CartItem;
}
export const removeOffer = (params?: RemoveOfferParams, requestParams?: ReduxRequestPassOptions) => (dispatch: Function, getState: Function): Promise<ActionResponse> => {
    return dispatch(
        request({
            getState,
            reduxType: types.REMOVE_CART_ITEM,
            method: 'DELETE',
            path: withVariables(Endpoints.PublicCartItem, params),
            params: params.id,
            requestParams: params,
            ...requestParams,
        })
    )
        .then((response) => {
            if (params?.cartItem.itemType === CartItemTypes.Voucher) {
                Tracking.eventRemoveVoucherFromCart(new GtmVoucherCartItem(params?.cartItem));
            } else {
                Tracking.eventRemoveFromCart(params?.cartItem);
            }

            dispatch(current());
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
};
