import TagManager from 'react-gtm-module';
import getConfig from 'next/config';

import Cart from 'models/Cart';
import CartItem from 'models/CartItem';
import GtmCartItem from 'models/GtmCartItem';
import GtmPaymentOrder from 'models/GtmPaymentOrder';
import GtmVoucherCartItem from 'models/GtmVoucherCartItem';

import { changeToCent } from 'utils/math';

export interface DataLayer {
    [key: string]: any;
}

export interface Tracking {
    init: (gtmId: string, dataLayer?: DataLayer) => void,
    isEnabled: () => boolean,
    event: (name: string, data: DataLayer) => void,
    eventCheckout: (cart: Cart, step: number) => void,
    eventPurchaseOffline: (cart: Cart, affiliation: string) => void,
    eventPurchaseOnline: (items: GtmPaymentOrder) => void,
    eventAddToCart: (cart: CartItem) => void,
    eventAddVoucherToCart: (voucher: GtmVoucherCartItem) => void,
    eventRemoveFromCart: (cart: CartItem) => void
    eventRemoveVoucherFromCart: (cart: GtmVoucherCartItem) => void,
    eventPromoProductClick: (any) => void;
    eventProductClick: (any) => void;
    eventProductDetail: (id: string, name: string, category: string) => void;
    eventSignUp: () => void;
    eventAuthentication: (userId: string) => void;
    eventContactForm: (topic: string, reason: string) => void;
    eventTestAppointment: (page: string, locationName: string) => void;
    eventSiteSearch: (searchTerm: string) => void;
    eventBuyNow: () => void;
}


const Tracking: Tracking = {
    init(gtmId, dataLayer = {}) {
        if (!this.isEnabled()) return;

        dataLayer = {
            ...dataLayer,
            originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
        };

        TagManager.initialize({
            gtmId,
            dataLayer,
        });
    },

    isEnabled() {
        const { publicRuntimeConfig } = getConfig();
        return Boolean(publicRuntimeConfig.GTM_ENABLED);
    },

    event(name, data) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: name,
                ...data,
            },
        });
    },

    eventCheckout(cart, step) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step },
                        products: cart.getCartProductsTrackingProps(),
                    },
                },
            },
        });
    },

    eventPurchaseOffline(cart, affiliation) {
        if (!this.isEnabled()) return;

        const pricing = cart.getTotalPrice(cart.items, cart.discountCode);

        TagManager.dataLayer({
            dataLayer: {
                event: 'purchase',
                ecommerce: {
                    purchase: {
                        currencyCode: cart.currency?.code,
                        actionField: {
                            id: cart.id,
                            revenue: pricing.finalPrice.toFixed(2),
                            coupon: cart.discountCode?.code,
                            affiliation,
                            dimension1: 'Gotówka',
                            dimension2: 'false',
                        },
                        products: cart.getCartProductsTrackingProps(),
                    },
                },
            },
        });
    },

    eventPurchaseOnline(purchaseData) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'purchase',
                ecommerce: {
                    purchase: {
                        currencyCode: purchaseData.currencyCode,
                        actionField: {
                            id: purchaseData.id,
                            revenue: purchaseData.finalPrice.toFixed(2),
                            coupon: purchaseData.discountCode,
                            affiliation: purchaseData.affiliation,
                            dimension1: purchaseData.paymentProvider,
                            dimension2: purchaseData.buyNow,
                        },
                        products: purchaseData.parseGtmItems(),
                    },
                },
            },
        });
    },


    eventAddToCart(cartItem) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: cartItem.item?.pricing.currency.code,
                    add: {
                        products: [cartItem.getTrackingProps()],
                    },
                },
            },
        });
    },

    eventAddVoucherToCart(voucher) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'addToCart',
                ecommerce: {
                    currencyCode: voucher.currency,
                    add: {
                        products: [voucher],
                    },
                },
            },
        });
    },

    eventRemoveVoucherFromCart(voucher) {
        if (!this.isEnabled()) return;

        const properPrice = voucher.name.includes('custom')
            ? voucher.price
            : changeToCent(voucher.price);

        TagManager.dataLayer({
            dataLayer: {
                event: 'removeFromCart',
                ecommerce: {
                    remove: {
                        products: [{ ...voucher, price: properPrice }],
                    },
                },
            },
        });
    },


    eventRemoveFromCart(cartItem) {
        if (!this.isEnabled()) return;
        const products = new GtmCartItem(cartItem);

        TagManager.dataLayer({
            dataLayer: {
                event: 'removeFromCart',
                ecommerce: {
                    remove: {
                        products: [products.cartItemData],
                    },
                },
            },
        });
    },

    eventPromoProductClick(promoProducts) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'promotionClick',
                ecommerce: {
                    promoClick: {
                        promotions: [promoProducts],
                    },
                },
            },
        });
    },

    eventProductClick(product) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'productClick',
                ecommerce: {
                    click: {
                        products: [product],
                    },
                },
            },
        });
    },

    eventProductDetail(id: string, name: string, category: string) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'detail',
                ecommerce: {
                    detail: {
                        products: [{
                            id, name, category,
                        }],
                    },
                },
            },
        });
    },

    eventSignUp() {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'sign_up',
            },
        });
    },

    eventAuthentication(userId: string) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'authentication',
                userId,
            },
        });
    },

    eventContactForm(topic: string = '', reason: string = '') {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'formularz_kontaktowy',
                topic,
                reason,
            },
        });
    },

    eventTestAppointment(page: string, locationName: string) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'wizyta_testowa',
                event_location: page,
                salon: locationName,
            },
        });
    },

    eventSiteSearch(searchTerm: string) {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'site_search',
                searchTerm,
            },
        });
    },

    eventBuyNow() {
        if (!this.isEnabled()) return;

        TagManager.dataLayer({
            dataLayer: {
                event: 'buyNow_popup',
            },
        });
    },
};

export default Tracking;
