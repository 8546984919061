export enum Endpoints {
    /**
     * Public module
     */

    // Markets
    PublicMarket = '/markets/:slug',

    // Redirects
    PublicRedirects = '/redirects',
    PublicRedirect = '/redirects/:id',

    // Misc
    PublicFileUpload = '/file-uploads',

    // User
    PublicUsers = '/users',
    PublicUsersPassword = '/users/password',
    PublicUsersToken = '/users/token',
    PublicUsersAssignCode = '/user-login-codes',
    PublicUsersCodesToken = '/user-login-codes/token',

    // Gus
    PublicGus = '/gus',

    // Short Url => Long Url
    PublicShortenLink = '/short-links',

    // Products
    PublicOffers = '/offers',
    PublicOffer = '/offers/:slug',

    // Special Products
    PublicProductsPromos = '/products-promos',
    PublicProductsPromo = '/products-promos/:slug',

    // Products
    PublicProducts = '/products',
    PublicProduct = '/products/:slug',

    // Suggested Products
    PublicProductsSuggested = '/suggested-products',

    // Visitors
    PublicVisitors = '/visitors',
    PublicVisitor = '/visitors/:id',

    // Carts
    PublicCarts = '/carts',
    PublicCart = '/carts/:id',
    PublicCartDiscountCode = '/carts/:id/discount-code',

    // Cart Products
    PublicCartItems = '/cart-items',
    PublicCartItem = '/cart-items/:id',

    // Discount Code verify,
    PublicDiscountCodeVerify = '/discount-codes/verify',

    // Orders
    PublicOrders = '/orders',

    // Order payments
    PublicOrderPayment = '/order-payments/:id',
    PublicOrderPaymentMakePayment = '/order-payments/:id/payment',

    // Locations
    PublicLocations = '/locations',
    PublicLocationsNearby = '/locations/nearby',
    PublicLocation = '/locations/:slug',
    PublicLocationsCities = '/locations/cities',

    // Cities
    PublicCities = '/cities',
    PublicCity = '/cities/:slug',

    // Payment transactions
    PublicPaymentTransaction = '/payment-transactions/:id',

    // Payment mails
    PublicMails = '/mails',

    // Visitors
    PublicLeads = '/leads',

    // Articles
    PublicArticles = '/articles',
    PublicArticlesByCategories = '/articles/by-category',
    PublicArticle = '/articles/:slug',
    PublicArticleRating = '/articles/:id/rating',

    // Article Categories
    PublicArticleCategories = '/article-categories',
    PublicArticleCategory = '/article-categories/:slug',

    // BodyPartGroups
    PublicBodyPartGroups = '/body-part-groups',

    // BodyParts
    PublicBodyParts = '/body-parts',

    // Vouchers
    PublicVouchers = '/vouchers',
    PublicVoucher = '/vouchers/:slug',

    // Opinions
    PublicOpinions = '/opinions',

    // SearchBar
    SearchBar = '/search',

    // Events
    PublicEvents = '/events',
    PublicEventsSlots = '/events/slots',
    PublicEventsFirstFreeDay = '/events/first-free-day',

    // CmsSections
    PublicCmsSections = '/cms-sections',
    PublicCmsPage = '/cms-pages/:slug',
    PublicCmsPages = '/cms-pages',

    // UserContact
    PublicUserContacts = '/contact',

    // InfluencerSubmitForm
    PublicInfluencerSubmitForm = '/influencer-submit-form',

    /**
     * Auth user module
     */

    // Profile
    AuthMe = '/me',
    AuthMeGender = '/me/gender',
    AuthMeInvoice = '/me/invoice',

    // Password
    AuthMePassword = '/me/password',

    // Contact
    AuthMeContact = '/me/contact',

    // UserAgrees
    AuthMeUserAgrees = '/me/user-agrees',

    // Influencers
    AuthMeInfluencers = '/me/influencer-submit-form',

    // OrderProducts
    AuthMeOrderProducts = '/me/order-products',
    AuthMeOrderProduct = '/me/order-products/:id',

    // Orders
    AuthMeOrders = '/me/orders',
    AuthMeOrder = '/me/orders/:id',

    // Events
    AuthMeEvents = '/me/events',
    AuthMeEvent = '/me/events/:id',
    AuthMeEventMove = '/me/events/:id/date',
    AuthMeEventDoneStatus = '/me/events/done-status',

    // OrderProducts
    AuthMeDiscountCodes = '/me/discount-codes',
}

export interface ListParamsDefault {
    page?: number;
    perPage?: number;
    enabled?: boolean;
    search?: string;
}

export enum OrderControls {
    AddToCart = 'addToCart',
    BuyNow = 'buyNow',
}
