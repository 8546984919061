export enum ApiResources {
    // Me
    AuthToken = 'authToken',
    Me = 'me',
    GusData = 'gusData',
    ShortLinkUrl = 'shortLinkUrl',

    // Misc
    Status = 'status',
    FileUpload = 'fileUpload',
    Market = 'market',

    // User
    Users = 'users',
    User = 'user',
    UserPasswordReset = 'userPasswordReset',

    // Products
    Offers = 'offers',
    Offer = 'offer',

    // Products
    Products = 'products',
    Product = 'product',

    // Suggested products
    SuggestedProducts = 'suggestedProducts',

    // Visitors
    Visitor = 'visitor',

    // Cart
    Cart = 'cart',

    // Cart items
    CartItems = 'cartItems',
    CartItem = 'cartItem',

    // Orders
    Orders = 'orders',
    Order = 'order',

    // Locations
    Locations = 'locations',
    Location = 'location',
    LocationsCities = 'locationCities',

    // Locations
    Cities = 'cities',
    City = 'city',

    // Order payemnts
    OrderPayments = 'orderPayments',
    OrderPayment = 'orderPayment',

    // Payment transactions
    PaymentTransactions = 'paymentTransactions',
    PaymentTransaction = 'paymentTransaction',

    // Searchbar
    SearchBar = 'search',

    // Leads
    Leads = 'leads',
    Lead = 'lead',

    // Articles
    Articles = 'articles',
    Article = 'article',

    // Article categories
    ArticleCategories = 'articleCategories',
    ArticleCategory = 'articleCategory',
    ArticlesByCategory = 'articleByCategory',

    // BodyPartGroups
    BodyPartGroups = 'bodyPartGroups',

    // BodyParts
    BodyParts = 'bodyParts',

    // Vouchers
    Vouchers = 'vouchers',
    Voucher = 'voucher',

    // Opinions
    Opinions = 'opinions',

    // Products promos
    ProductPromos = 'productPromos',
    ProductPromo = 'productPromo',

    // EventSlots
    EventSlots = 'eventSlots',
    EventFirstFreeDay = 'eventFirstFreeDay',
    EventSlot = 'eventSlot',

    // Cms Sections
    CmsSections = 'cmsSections',
    CmsPage = 'cmsPage',
    CmsPages = 'cmsPages',

    // OrderProducts
    OrderProducts = 'orderProducts',
    OrderProduct = 'orderProduct',

    // Events
    Events = 'events',
    Event = 'event',

    // DiscountCodes
    DiscountCodes = 'discountCodes',
    DiscountCode = 'discountCode',

    // Redirects
    Redirects = 'redirects',
    Redirect = 'redirect',
}
