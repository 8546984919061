import ApiMarket from 'types/api/Market';

import Currency from 'models/Currency';
import Locale from 'models/Locale';
import Model from 'models/Model';

export const Timezone = 'Europe/London';

export default class Market implements Model {
    id: string;
    name: string;
    slug: string;
    enabled: boolean;
    gtmId: string;
    additionalVisitTime: number;
    currencies: Currency[];
    locales: Locale[];

    constructor(data: ApiMarket) {
        this.id = data.id;
        this.name = data.name;
        this.slug = data.slug;
        this.gtmId = data.gtmId;
        this.enabled = data.enabled;
        this.additionalVisitTime = data.additionalVisitTime;
        this.locales = Array.isArray(data.locales)
            ? data.locales.map(locale => new Locale(locale))
            : [];
        this.currencies = Array.isArray(data.currencies)
            ? data.currencies.map(currency => new Currency(currency))
            : [];
    }
}
