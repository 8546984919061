import { createContext } from 'react';
import { config } from 'locales/common/localeConfig';
import { Locale } from 'locales/types/locale';

import { Locales } from 'types/locale';

export interface LocaleContext {
    locale: Locales;
    translations: Locale;
    config: typeof config;
}

const initialState: LocaleContext = {
    locale: Locales.En,
    translations: null,
    config: null,
};

const LocaleContext = createContext(initialState);
export default LocaleContext;
