export enum Routes {
    // Prefixes
    PrefixAdmin = '/admin/',
    PrefixPanel = '/panel/',
    PrefixAccount = '/account/',

    // Public routes
    PublicHome = '/',
    PublicMenZoneSlug = '/men-zone/',
    PublicWomenZoneSlug = '/women-zone/',
    PublicProduct = '/[genderZoneSlug]/[slug]/',
    PublicSpecialOffers = '/special-offers/',
    PublicSpecialOffersMen = '/special-offers-for-men/',
    PublicSpecialOffersWomen = '/special-offers-for-women/',
    PublicSpecialOffersMenSlug = '/special-offers-for-men/[slug]/',
    PublicSpecialOffersWomenSlug = '/special-offers-for-women/[slug]/',
    PublicSpecialOffer = '/special-offers/[slug]/',
    PublicBlog = '/blog/',
    PublicBlogArticle = '/blog/[articleCategorySlug]/[slug]/',
    PublicBlogCategory = '/blog/[articleCategorySlug]/',
    PublicLocation = '/clinics/[slug]/',
    PublicLocations = '/clinics/',
    PublicPriceList = '/laser-hair-removal-price-list/',
    PublicTenForAnimal = '/10-for-animal/',
    PublicEndermology = '/endoshape/',
    PublicFaq = '/faq/',
    PublicAbout = '/about-us/',
    PublicReviewerClub = '/reviewer-club/',
    PublicCart = '/cart/',
    PublicCartReservation = '/cart/reservation/',
    PublicCartSummary = '/cart/summary/',
    PublicTechnology = '/lasers-for-depilation/',
    PublicPersonalDataProtection = '/personal-data-protection/',
    PublicLocationRegulations = '/terms-and-conditions-of-use-of-location-services/',
    PublicMenZone = '/men-zone/',
    PublicWemanZone = '/women-zone/',
    PublicReviews = '/reviews/',
    PublicCheckout = '/summary/',
    PublicContact = '/contact/',
    PublicPaymentSummary = '/payment/[id]/',
    PublicPaymentConfirm = '/payment/[id]/confirmation/',
    PublicPrivacyPolicy = '/privacy-policy/',
    PublicBusinessIdea = '/franchise/',
    PublicRecommendationProgram = '/recommendation-program/',
    PublicRecommendationProgramRegulations = '/regulations-program-recommendation/',
    PublicRegulations = '/terms-and-conditions/',
    PublicVouchers = '/vouchers/',

    // Panel routes
    Panel = '/panel/',
    PanelSettings = '/panel/settings/',
    PanelOrders = '/panel/orders/',
    PanelSpecialOffers = '/panel/offers/',
    PanelBeautyClub = '/panel/beauty-club/',
    PanelContact = '/panel/contact/',
    PanelInfluencer = '/panel/influencer/',
    PanelPrivacyProtection = '/panel/privacy-protection/',

    AccountLogin = '/account/login/',
    AccountRegister = '/account/register/',
    AccountPasswordReset = '/account/password-reset/',
    AccountPasswordChange = '/account/password-change/',
    AccountActivation = '/account/activation/',
}
