import moment, { Moment } from 'moment-timezone';

import ApiArticle from 'types/api/Article';
import { Routes } from 'types/routes';

import ArticleAuthor from 'models/ArticleAuthor';
import ArticleCategory from 'models/ArticleCategory';
import ArticleLocale from 'models/ArticleLocale';
import { Timezone } from 'models/Market';
import Model from 'models/Model';
import Product, { Genders } from 'models/Product';

import { getFormattedDate } from 'utils/date';
import { withVariables } from 'utils/string';

export enum CtaTypes {
    ContactForm = 'contactForm',
    LeadForm = 'leadForm',
    Offer = 'offer',
    Product = 'product',
}

export default class Article implements Model {
    id: string;
    publishedAt: Moment;
    publishedAtDisplay: string;
    publishedAtDisplayAlterFormat: string;
    author: ArticleAuthor;
    locale: ArticleLocale;
    category: ArticleCategory;
    sideCategories: ArticleCategory[];
    rating: number;
    ratingsCount: number;
    ratingDisplay: string;
    model: Article;
    href: string;
    ctaType: CtaTypes;
    ctaTypeData?: {
        product?: Product;
    };
    gender: Genders;
    relatedArticles: Article[];
    products?: Product[];

    constructor(data: ApiArticle) {
        this.id = data.id;
        this.publishedAt = data.publishedAt && moment.utc(data.publishedAt).tz(Timezone);
        this.publishedAtDisplay = this.publishedAt && getFormattedDate(this.publishedAt, 'date');
        this.publishedAtDisplayAlterFormat = data.publishedAt && moment.utc(data.publishedAt).tz(Timezone).format('LL');
        this.author = data.author && new ArticleAuthor(data.author) || null;
        this.category = data.category && new ArticleCategory(data.category) || null;
        this.locale = data.locale && new ArticleLocale(data.locale, this.category) || null;
        this.sideCategories = Array.isArray(data.sideCategories) && data.sideCategories.map(sideCategory => new ArticleCategory(sideCategory));
        this.rating = data.rating;
        this.ratingDisplay = this.rating?.toFixed(2) || null;
        this.ratingsCount = data.ratingsCount;
        this.href = withVariables(Routes.PublicBlogArticle, {
            articleCategorySlug: this.category?.locale?.slug,
            slug: this.locale?.slug,
        });
        this.ctaType = data.ctaType;
        this.ctaTypeData = data.ctaTypeData
            ? {
                product: data.ctaTypeData?.product && new Product(data.ctaTypeData?.product) || null,
            }
            : null;
        this.gender = data.gender;
        this.relatedArticles = data?.relatedArticles || [];
        this.products = Array.isArray(data?.products)
            ? data?.products?.map(product => new Product(product))
            : [];
    }
}
