import { NextPage } from 'next';

import NewImage from 'components/layout/NewImage';

import StyledComponent from './styles';

const PublicPageClose: NextPage = ({ }) => {
    return (
        <StyledComponent className="public-page-close">
            <NewImage
                className="header-logo"
                src="/theme/images/logo-envie-light.svg"
                alt="envie.co.uk"
            />
            <div className="modal">
                <h2 className="modal__header">Sorry</h2>
                <p className="modal__paragraph">
                    We would like to inform you that as of <b>31 March 2024</b> all Envie clinics will have been permanently closed.
                </p>
                <p className="modal__paragraph">
                    We would like to thank all our customers, partners and colleagues for the trust and support shown over the years.<br />If you have any questions, please do not hesitate to contact us via email at
                    {' '}
                    <a
                        className="modal__paragraph__link"
                        href={'mailto:contact@envieclinic.co.uk'}
                    >
                        contact@envieclinic.co.uk.
                    </a>
                </p>
                <p className="modal__paragraph">
                    Once again, we want to thank you all for your cooperation and wish you every success in the future.
                </p>
            </div>
        </StyledComponent>
    );
};

export default PublicPageClose;
